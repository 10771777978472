<template>
  <div>
    <b-container class="base-container-x pt-2" style="min-height:400px;background-color:#fff">
      <b-row class="mb-5 justify-content-center">
        <b-col cols="12 d-none">
          <StepFormRegister :currentStep="0" />
        </b-col>
        <b-col xl="9" lg="9" sm="12" md="12" class="mt-4 mt-md-5">
          <h3 class="content-header-title text-primary-dark">{{ $t('Select Type') }}</h3>
          <span class="text-18 text-gray-light">{{ $t('Please select the type of apply.') }}</span>
          <div>
            <vue-horizontal-list :items="items" :options="options">
              <template v-slot:default="{item}">
                <div class="item item-popular rounded-lg">
                  <div class="img-wrapper-apply rounded" @click="onSelectedType(item.type)">
                    <b-img-lazy class="border-0" :src="PUBLIC_PATH+'img/apply_form/'+item.image"></b-img-lazy>
                    <div :class="item.isActive ? 'p-3 overlay-text active text-primary-dark text-center' : 'p-3 overlay-text text-primary-dark text-center'" @click="selectTypeApply(item.type)">
                      <span class="d-block font-weight-bold" v-html="item.title"></span>
                    </div>
                  </div>
                </div>
              </template>
            </vue-horizontal-list>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VueHorizontalList from 'vue-horizontal-list'
import StepFormRegister from '../components/form/StepFormRegister'

export default {
  name: 'ApplyType',
  title: ' | Select apply type',
  components: {
    VueHorizontalList,
    StepFormRegister
  },
  data () {
    return {
      applySteps: [this.$t('Start'), this.$t('School information'), this.$t('Yoga information'), this.$t('Document'), this.$t('Finished')],
      currentStep: 1,
      options: {
        responsive: [
          { end: 576, size: 3 },
          { start: 576, end: 768, size: 3 },
          { start: 700, end: 730, size: 3 },
          { size: 3 }
        ],
        list: {
          // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
          windowed: 1200,
          // Because: #app {padding: 80px 24px;}
          padding: 10
        }
      },
      items: [
        {
          type: 'RSY',
          image: 'apply_rsy_bg.png',
          title: this.$t('Apply for School Registration (RSY)'),
          isActive: false
        },
        {
          type: 'RTY',
          image: 'apply_rsy_bg.png',
          title: this.$t('Apply for Teacher Registration (RTY)'),
          isActive: false
        }
        // {
        //   type: 'RSY + RTY',
        //   image: 'apply_rsy_rty_bg.png',
        //   title: this.$t('Apply for School & Teacher Registration (RSY + RTY)'),
        //   isActive: false
        // }
      ]
    }
  },
  mounted () {
    const applyType = Object.prototype.hasOwnProperty.call(this.$route.query, 'type') ? this.$route.query.type : 'RSY'
    this.selectTypeApply(applyType)
  },
  methods: {
    selectTypeApply (type) {
      this.items.forEach(item => {
        if (item.type === type) {
          item.isActive = true
        } else {
          item.isActive = false
        }
      })
    },
    onSelectedType (type) {
      if (type === 'RSY') {
        this.$router.push({ name: 'schoolRegisterInformation' })
      } else if (type === 'RTY') {
        this.$router.push({ name: 'teacherRegisterInformation' })
      }
    }
  }
}
</script>
<style >
  .img-wrapper-apply .overlay-text {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    height: 100%;
    width: 100%;
    font-size: 1.2rem;
  }
  .img-wrapper-apply .overlay-text:hover, .img-wrapper-apply .overlay-text.active {
    background: #21315983;
    color:white !important;
    cursor: pointer;
  }
</style>
